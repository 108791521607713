import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Collections from "../components/Collections"
import LandingPage from "../components/LandingPage"

const IndexPage = () => {
  return (
    <Layout>
      <SiteMetadata title="Sebastian Díaz de León" description="Catalogue | Sebastian Díaz de León" />
      <LandingPage />
    </Layout>
  )
}

export default IndexPage


